// Import JS components:
import Modal from './js/modal.js';

// Import SCSS assets
require('./scss/site.scss');
require('./scss/sidebar/sidebar.scss');
require('./scss/modal/modal.scss');

const modals = document.querySelectorAll('.modal');
[...modals].forEach(modal => new Modal(modal));

if (module.hot) {
  module.hot.accept();
}
