'use strict';

export default class Modal {

  /**
   * Modal window constructor.
   * @param element
   */
  constructor(element) {
    this.element = element;
    this.visible = false;
    this.visible_class = 'modal--visible';
    this.modal = element.querySelector('.modal__modal');

    this.close_button = element.querySelector('.modal__close');
    this.close_button.addEventListener('click', this.close.bind(this));

    this.trigger_buttons = document.querySelectorAll('[data-modal-trigger]');
    [...this.trigger_buttons].forEach(button => button.addEventListener('click', this.open.bind(this)));

    element.addEventListener('click', this.clickOutside.bind(this));
    document.addEventListener('keydown', this.onKeyPress.bind(this));
  }

  /**
   * Open the modal.
   */
  open() {
    if (!this.visible) {
      this.visible = true;
      this.element.classList.add(this.visible_class);
    }
  }

  /**
   * Close the modal.
   */
  close() {
    if (this.visible) {
      this.visible = false;
      this.element.classList.remove(this.visible_class);
    }
  }

  /**
   * Close the model if you click outside of the box.
   * @param event
   */
  clickOutside(event) {
    if (this.visible && !this.modal.contains(event.target)) {
      this.close();
    }
  }

  /**
   * Handle keypress events, close modal on escape key press.
   * @param event
   */
  onKeyPress(event) {
    if (event.keyCode === 27) {
      this.close();
    }
  }

}
